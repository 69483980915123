"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginStep = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const common_types_1 = require("@as-react/common-types");
const cotopaxi_1 = require("@as/cotopaxi");
const AuthActions_creators_1 = require("../../../../../redux/authentication/actions/AuthActions.creators");
const AuthSelectors_1 = require("../../../../../redux/authentication/selectors/AuthSelectors");
const LoginUtil_1 = require("../../../../../util/LoginUtil");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../../../constants/i18nConstants");
const LoginForm_1 = require("../forms/LoginForm");
const ForgotPasswordForm_1 = require("../forms/ForgotPasswordForm");
const LoginStep = ({ initialEmail }) => {
    const { t } = (0, react_i18next_1.useTranslation)([
        i18nConstants_1.I18N_NAMESPACES.account,
        i18nConstants_1.I18N_NAMESPACES.checkout,
        i18nConstants_1.I18N_NAMESPACES.general,
        i18nConstants_1.I18N_NAMESPACES.navigation,
    ]);
    const [forgotPasswordActive, setForgotPasswordActive] = (0, react_1.useState)(false);
    const [email, setEmail] = (0, react_1.useState)(initialEmail);
    const [isEmailValid, setIsEmailValid] = (0, react_1.useState)(false);
    const [skipValidationFor, setSkipValidationFor] = (0, react_1.useState)(initialEmail);
    const loading = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginLoading)());
    const loginError = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetLoginError)());
    const resetPasswordState = (0, react_redux_1.useSelector)((0, AuthSelectors_1.makeGetResetPasswordState)());
    const dispatch = (0, react_redux_1.useDispatch)();
    const handleLoginSubmit = (values) => {
        dispatch(AuthActions_creators_1.authActions.registeredLogin({
            username: values.email,
            password: values.password,
            keepSignedIn: false,
            reloadOnSuccess: false,
            location: common_types_1.AuthFormLocation.CHECKOUT,
        }));
    };
    const handleResetPasswordSubmit = (values) => {
        dispatch(AuthActions_creators_1.authActions.createResetCredentialsAction(values.email));
    };
    const handleSubmitGuest = () => {
        isEmailValid && dispatch(AuthActions_creators_1.authActions.createLoginGuestAction(email));
    };
    const handleEmailValidate = (error) => {
        setIsEmailValid(!error);
    };
    const resetForgotPasswordState = () => {
        (resetPasswordState.success || resetPasswordState.error) &&
            dispatch(AuthActions_creators_1.authActions.createResetCredentialsSuccessAction(null));
    };
    const toggleView = () => {
        setForgotPasswordActive(!forgotPasswordActive);
        resetForgotPasswordState();
    };
    const handleEmailChange = (email) => {
        setEmail(email);
        skipValidationFor && email !== skipValidationFor && setSkipValidationFor(undefined);
    };
    const handleForgotPasswordEmailChange = (email) => {
        handleEmailChange(email);
        resetForgotPasswordState();
    };
    return forgotPasswordActive ? ((0, jsx_runtime_1.jsx)(ForgotPasswordForm_1.ForgotPasswordForm, { initialEmail: email, onEmailChange: handleForgotPasswordEmailChange, onSubmit: handleResetPasswordSubmit, onShowLoginFormClick: toggleView, errorFeedback: resetPasswordState.error ? t(resetPasswordState.error) : undefined, successFeedback: resetPasswordState.success ? t(resetPasswordState.success) : undefined })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(LoginForm_1.LoginForm, { initialEmail: email, loading: loading, onEmailChange: handleEmailChange, onEmailValidate: handleEmailValidate, errorFeedback: (0, LoginUtil_1.getLoginError)(loginError, t), onSubmit: handleLoginSubmit, forgotPasswordClick: toggleView, skipValidationFor: skipValidationFor }), (0, jsx_runtime_1.jsx)(cotopaxi_1.Box, { mobile: { spacing: { marginTop: 4, padding: 2.5 }, theme: cotopaxi_1.BoxTheme.PRIMARY }, children: (0, jsx_runtime_1.jsxs)(cotopaxi_1.Group, { column: true, gutter: cotopaxi_1.GroupGutter.SMALL, children: [(0, jsx_runtime_1.jsx)(cotopaxi_1.Heading, { size: cotopaxi_1.HeadingSize.XXS, children: t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.step2.guest.banner.title`) }), (0, jsx_runtime_1.jsxs)(cotopaxi_1.Text, { size: cotopaxi_1.TextSize.SMALL, children: [t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.step2.guest.banner.message.part1`), (0, jsx_runtime_1.jsx)(cotopaxi_1.Button, { variant: cotopaxi_1.ButtonVariant.LINK, text: t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.step2.guest.banner.submit`), onClick: handleSubmitGuest }), t(`${i18nConstants_1.I18N_NAMESPACES.account}:checkout.login.flows.step2.guest.banner.message.part2`)] })] }) })] }));
};
exports.LoginStep = LoginStep;
